import { NuxtAxiosInstance } from "@nuxtjs/axios"
import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime"
import { SnowplowService } from "./Snowplow"
import { SET_EXPERIMENTS_DATA } from "~/store/experiments/actions"

export class TestLabService {
  constructor(
    private axios: NuxtAxiosInstance,
    private config: NuxtRuntimeConfig,
    private snowplowService: SnowplowService,
    private store
  ) {}

  // use stage-testlab for non production data
  getTestLabHost() {
    const currentHost = window.location.hostname
    const configHost = this.config.testLabHost
    if (currentHost === "www.anyvan.com") {
      return configHost
    } else if (configHost.includes("stage")) {
      return configHost
    } else {
      return "stage-" + configHost
    }
  }

  async getExperimentGroup(experiment: String) {
    const entityId = this.snowplowService.getSnowplowDomainIds().domain_userid
    const testLabHost = this.getTestLabHost()
    const response = await this.axios.post(
      testLabHost + "/api/v1/experiment/allocation",
      {
        entity: "SNOWPLOW_DOMAIN_USERID",
        entityId,
        experiment
      },
      {
        timeout: 1000
      }
    )
    if (response.status >= 200 && response.status < 300) {
      const variant = response.data.variant
      const experimentGroup = {
        entity: "SNOWPLOW_DOMAIN_USERID",
        entityId,
        experiment,
        variant
      }
      return experimentGroup
    }
  }

  async preallocate(experimentName) {
    const entityId = this.snowplowService.getSnowplowDomainIds().domain_userid
    const response = await this.axios.get(
      this.config.testLabHost +
        "/api/v1/experiment/pre-allocation/SNOWPLOW_DOMAIN_USERID/" +
        entityId +
        "/" +
        experimentName
    )
    return response.data.variant
  }

  allocate(experimentName, variant) {
    const entityId = this.snowplowService.getSnowplowDomainIds().domain_userid
    this.axios.post(this.config.testLabHost + "/api/v1/experiment/allocation", {
      entity: "SNOWPLOW_DOMAIN_USERID",
      entityId,
      experiment: experimentName,
      variant
    })
  }

  allocateAfterPreallocation(experimentContextObject) {
    const entityId = this.snowplowService.getSnowplowDomainIds().domain_userid
    this.axios.post(this.config.testLabHost + "/api/v1/experiment/allocation", {
      entity: "SNOWPLOW_DOMAIN_USERID",
      entityId,
      experiment: experimentContextObject.data.experiment,
      variant: experimentContextObject.data.variant
    })
  }

  findExperiments(blocks: any[], experiments: Set<string> = new Set()): Set<string> {
    for (const block of blocks) {
      for (const attribute of block.attributes) {
        if (attribute.name === "className" && attribute.value.includes("av-experiment")) {
          const classNames: string[] = attribute.value.split(" ")
          if (classNames.length > 1) {
            const experimentClass = classNames.find(className => className.startsWith("avx-name-"))
            if (experimentClass) {
              const experimentName = experimentClass.substring("avx-name-".length)
              experiments.add(experimentName)
            }
          }
        }
      }
      if (block.innerBlocks && block.innerBlocks.length > 0) {
        this.findExperiments(block.innerBlocks, experiments)
      }
    }
    return experiments
  }

  async handleExperiments(block: any): Promise<void> {
    if (process.server) {
      return
    }
    // check if testlab context cookie is present
    const experimentContextObject = this.snowplowService.getExperimentContextFromCookie()
    if (experimentContextObject) {
      //  fire request to testlab for allocation
      this.allocateAfterPreallocation(experimentContextObject)
    }

    const experiments = this.findExperiments(block)
    const experimentData = new Map<string, any>()

    const fetchPromises = Array.from(experiments).map(async experiment => {
      try {
        const data = await this.getExperimentGroup(experiment)
        experimentData.set(experiment, data?.variant)
        this.snowplowService.addExperimentContext(data)
      } catch (error) {
        console.log(error)
      }
    })
    await Promise.all(fetchPromises)
    this.store.commit("experiments/" + SET_EXPERIMENTS_DATA, experimentData)
  }
}
